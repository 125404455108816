import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames'

import CenterColumn from '../../components/Bulma/CenterColumn'
import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import TheaterVideo from "@bit/azheng.joshua-tree.theater"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

function ReferringProvider(props) {

  if (!props.data.hasThisSection) {
    return <div></div>
  }

  const mainEnglishClasses = classNames(
    "continuum", 
    "is-vcentered",
    {
      "color-back": props.colorBack
    }
  );

  
  let post = props.data
  let language = "en"

  return (
    <>
      {language === "es" ?
        <HorizontalTwoColumns
          className="continuum es-continuum"
          leftColWidth={3}
          rightColWidth={3}
          middleColWidth={2}
          leftColumn={
            <div 
              className="column is-7"
              data-aos="fade-right" 
            data-aos-duration="1200"
            >
              <h2>Atención Continua de Nuestros Odontólogos Remitentes</h2>
              <p className="large">La conexión del consultorio con su red de remisiones es esencial. Nuestro equipo promueve una comunicación abierta entre nuestros consultorios remitentes de modo que los pacientes puedan disfrutar de una experiencia óptima, lo que en última instancia mejora la coordinación de su atención.</p>
            </div>
          }
          rightColumn={
            <div 
              className="column"
              data-aos="fade-left" 
              data-aos-duration="1200"
            >
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.imageId}
                responsive
              />
            </div>
          }
        />
        :
        <section className={mainEnglishClasses}>
          <CenterColumn
            leftColWidth={4}
            rightColWidth={4}
            centerColumn={
              <div className="column">
              <h2 className="h3-style is-hidden-widescreen" style={{marginTop: 0}}>{post.heading}</h2>
              </div>
            }
          />
          
          <HorizontalTwoColumns
            leftColWidth={4}
            rightColWidth={4}
            middleColWidth={1}
            leftColumn={
              <div 
                className="column is-9" 
                style={{margin: "auto 0"}}
                data-aos="fade-right" 
                data-aos-duration="1200"
              >
                <h2 className="h3-style is-hidden-narrowscreen" style={{marginTop: 0}}>{post.heading}</h2>
                <MarkdownViewer markdown={post.text} />
                <div className="referring-provider-theater-button-desktop">
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                    playing={true}
                    onlyButton={true}
                    sizzleButton={true}
                    buttonClass="sizzle-button-under contained"
                    compId={"sizzleTheaterButton"}
                    language={language}
                  />
                </div>
              </div>
            }
            rightColumn={
              <div 
                className="column"
                data-aos="fade-left" 
                data-aos-duration="1200"
              >
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={post.imageId}
                  responsive
                />
                <p style={{
                  color: "#6d6e6f",
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: "bold",
                  marginTop: "19px",
                  marginBottom: "0"
                  }}
                >
                  {post.imageCaption}
                </p>
                <div className="referring-provider-theater-button-mobile">
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=L1FIWtc92K4`}
                    playing={true}
                    onlyButton={true}
                    sizzleButton={true}
                    buttonClass="referring-provider-button contained"
                    compId={"sizzleTheaterButton"}
                    language={language}
                  />
                </div>
              </div>
            }
          />
        </section>
        }
    </>
  )
}

export default ReferringProvider
