import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { shuffleArray, sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
// import CenterColumn from "../components/Bulma/CenterColumn"
// import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
import StaticHero from './index/StaticHero'
// import StaticHeading from "./index/StaticHeading"
// import Sizzle from "./index/Sizzle"
// import HomeCarousel from "./index/HomeCarousel"
import ImageText from "./index/ImageText"
import AllRatingsReviews from '../components/SocialReviews/AllSocialReviews';
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import ReferringProvider from "./index/ReferringProvider"
// import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
// import HorizontalTwoColumns from "../components/Bulma/HorizontalTwoColumns"
import LearnMoreCards from "./index/LearnMoreCards"
// import Button from '@bit/azheng.joshua-tree.button';
// import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import ImageTexts from "../components/ImageTexts"
import Exparel from './index/Exparel'
import ASIRD from './index/ASIRD'
import AAAHC from './index/AAAHC'


class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      didScroll: false,
      scrollPosition: "relative",
      scrollOverflow: "unset",
      playing: false,
      extTheater: false,
      toggleCarouselsClass: false
    }

  }



  componentDidMount() {

  }

  render() {

    var pageContext = this.props.pageContext
    var post
    var language = this.props.pageContext.language

    if (pageContext && pageContext.language) {
      switch (language) {
        case "en":
          post = this.props.data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = this.props.data.spanishIndex.nodes[0]

          break
        default:
          post = this.props.data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = this.props.data.allUniquePagesJson.nodes[0]
    }

    var staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1,
    }
    var sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200,
    }

    let allReviews = this.props.data.allReviews.nodes;
    if (language === "es") allReviews = this.props.data.allReviewsEs.nodes;
    let sortedReviews = sortReviewsByDate(allReviews);

    //shuffleArray(allReviews);
    let fixedFacewallReviews = sortedReviews.slice(0, 8)

    return (
      <SharedStateProvider>
        <Layout
        className={`main-homepage ${language === "es" ? "es" : null}`}
        language={language}
        layoutClass={"homepage-fab"}
        noFab={true}
        pageTitle={"main-homepage"}
        toastText={language === "en" ? <><span className="whole-div"><Link to="/wisdom/"></Link></span><div className="toast-message-container"><a>Need your wisdom teeth removed? Apply for our Wisdom For Wisdom program. <span className="bold-underline">Learn&nbsp;More</span></a></div></> : null}
        >
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            schemaPage={post.schemaPage}
            pathname={this.props.location.pathname}
          />
          {/* {language !== "es" && <BackToBusiness tel="(619) 287-5000" telStripped="+16192875000" />} */}

          <div className="static-container" style={staticStyle}>

            {/* {post.hasSizzle
              ? <Sizzle
                  playing={this.state.playing}
                  sizzleButtonStyle={sizzleButtonStyle}
                  post={post}
                  language={language}
                >
                  <StaticHeading
                    playing={this.state.playing}
                    sizzleButtonStyle={sizzleButtonStyle}
                    post={post}
                    language={language}
                  />
                </Sizzle>

              : <StaticHero
                  post={post}
                />
            }

 {/* <Sizzle
                  playing={this.state.playing}
                  sizzleButtonStyle={sizzleButtonStyle}
                  post={post}
                  language={language}
                >
                  <StaticHeading
                    playing={this.state.playing}
                    sizzleButtonStyle={sizzleButtonStyle}
                    post={post}
                    language={language}
                  />
                </Sizzle> */}
              <StaticHero
                  post={post}
                />


              {/* <ImageText language={language}  post={post} textImage noReverse /> */}


              <ImageTexts language={language} imageTopMobile imageTexts={post.imageTexts} />

              {/* <HomeCarousel language={language} toggleCarouselsClass={this.state.toggleCarouselsClass} /> */}
              {/* <FixedFacewallHomepage language={language} heading={language === "es" ? "Escuche Acerca de Nuestros Pacientes" : "Hear From Our Patients"} reviews={fixedFacewallReviews} /> */}

              {/* <HeadingVideo post={post} /> */}

              <AAAHC post={post.homeAAAHC} language={language}/>

              <LearnMoreCards language={language} post={post.learnMoreCards} />

              <Exparel post={post.homeExparel} />

              <InTheCommunity colorBack={false} language={language} data={post.inTheCommunity} />

              <ReferringProvider colorBack={true} language={language} data={post.referringProvider} />

              <ASIRD post={post.homeAsird} />

              {/* <FirstTime colorBack={false} language={language} post={post} language={language} noReverse /> */}

              {post.homeRatingsReviews.hasThisSection &&
                <AllRatingsReviews
                  colorBack={false}
                  animation
                  heading={post.homeRatingsReviews.heading}
                  language={language}
                  buttonHref={post.homeRatingsReviews.buttonOne.href}
                  buttonText={post.homeRatingsReviews.buttonOne.buttonText}
                />
              }

          </div>

        </Layout>
      </SharedStateProvider>
    )
  }
}


IndexPage.propTypes = {
  pageContext: PropTypes.object
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    allReviews: allReviewsJson(
      filter: {
        reviewType: { eq: "Patient" }
        reviewLanguage: { ne: "ESP" }
      }
    ) {
      nodes {
        ...FacewallData
      }
    }
    allReviewsEs: allSpanishReviewsJson(
      filter: {
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ESP" }
      },
      limit: 4
    ) {
      nodes {
        title
        reviewerName
        reviewType
        reviewLanguage
        thumbnailPublicId
        mainProcedureDone
        monthYear {
          month
          year
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        intro
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
        hasThisSection
        imageId
        blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          logos {
            imageId
          }
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
        blurb
        hasThisSection
        heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        schemaPage
      }
    }
    spanishIndex: allSpanishUniquePagesJson(filter: { title: { eq: "/es/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        intro
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
        hasThisSection
        imageId
        blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          logos {
            imageId
          }
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
        blurb
        hasThisSection
        heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        schemaPage
      }
    }
  }
`

export default IndexPage
