import React from "react"
import classNames from "classnames"

import Button from "@bit/azheng.joshua-tree.button"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../../components/ImageMeta"

const AAAHC = ({ post, language }) => {
  if (!post || !post.hasThisSection) return <></>

  const imgBtnClasses = classNames("image-btn-row", {
    "no-btn": !post.button.useButton,
  })
  const bgImage = {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
  }

  return (
    <div
      className="p-section color-back home-aaahc"
      style={post.backgroundImage ? bgImage : {}}
    >
      <div className="columns" data-aos-duration="1200" data-aos="fade-up">
        <div className="column is-3"></div>
        <div className="column">
          <h3 className="has-text-centered">{post.heading}</h3>
          <MarkdownViewer markdown={post.blurb} />

          <div className="aaahc-logos">
            {post.logos &&
              post.logos.map((img, i) => (
                <React.Fragment key={i}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={img.imageId}
                    responsive
                  />
                </React.Fragment>
              ))}
          </div>

          {post.button && post.button.useButton && (
            <div className={imgBtnClasses}>
              <Button
                contained
                href={post.button.href}
                buttonText={post.button.buttonText}
              />
            </div>
          )}
        </div>
        <div className="column is-3"></div>
      </div>
    </div>
  )
}

export default AAAHC
