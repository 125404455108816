import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Stars from "./Stars";
import Button from "@bit/azheng.joshua-tree.button"
import classnames from 'classnames'

const AllSocialReviews = ({ language, heading, buttonHref, buttonText, isColumn, colorBack, animation }) => {

    const animate = animation ? "fade-up" : "";

    const wrapperClasses = classnames({
        "home-section": heading,
        "ratings-review top-down": true,
        "color-back": colorBack
    })

    const data = useStaticQuery(graphql`
        query SocialQuery {
            locations: allLocationsJson(sort: {fields: locationData___order}) {
                nodes {
                  locationData {
                    gmbId
                    facebookId
                    cityName
                    locationName
                    order
                    gmbOverride
                  }
                }
              }
              facebookSocials: allSocialJson(sort: {fields: data___totalReviews, order: DESC}, filter: {data: {platform: {eq: "facebook"}}}) {
                nodes {
                  data {
                    platformId
                    rating
                    totalReviews
                    url
                    platform
                  }
                }
              }
              gmbSocials: allSocialJson(sort: {fields: data___totalReviews, order: DESC}, filter: {data: {platform: {eq: "googleMyBusiness"}}}) {
                nodes {
                  data {
                    platformId
                    rating
                    totalReviews
                    url
                    platform
                  }
                }
              }
        }
    `)


    let locations = data.locations.nodes;

    let googleReviews = [];
    let facebookReviews = [];
    let googles = data.gmbSocials.nodes;
    let facebooks = data.facebookSocials.nodes;

    googleReviews = matchSocialsWithLocations(locations, googles, "google");
    facebookReviews = matchSocialsWithLocations(locations, facebooks, "facebook", false);

    function matchSocialsWithLocations(locations, platformArr, platform, useCityName = true) {
        let reviews = [];
        let map = {}

        locations.forEach(location => {
            let reviewObj = {};

            if (platform === "google" && map[location.locationData.gmbId]) {
                return
            } else {
                map[location.locationData.gmbId] = platform
            }

            if (platform === "facebook" && map[location.locationData.facebookId]) {
                return
            } else {
                map[location.locationData.facebookId] = platform
            }
            

            for (let i = 0; i < platformArr.length; i++) {
                
                if (Math.round(platformArr[i].data.rating) < 3) return

                if (platformArr[i].data.platformId === location.locationData.facebookId ||
                    platformArr[i].data.platformId === location.locationData.gmbId
                    ) {
                    reviewObj = { ...platformArr[i].data };
                }
            }

            if (useCityName) {
                reviewObj.name = location.locationData.locationName;
            } 

            // Override the map google link, to a different gmb link
            if (platform === "google" && location.locationData.gmbOverride) {
                reviewObj.url = location.locationData.gmbOverride
            }
            
            reviewObj.order = location.locationData.order;
    
            reviews.push(reviewObj);
        });

        return reviews;
    }
    
    // if (!reviews) {
    //     reviews = [
    //         {name: "Franklin", ...l1},
    //         {name: "Thompson's Station", ...l2},
    //         {name: "Franklin", ...l3},
    //         {name: "Thompson's Station", ...l4},
    //     ];
    // }

    googleReviews.sort((a, b) => (a.order > b.order ? 1 : -1))
    facebookReviews.sort((a, b) => (a.order > b.order ? 1 : -1))

    let consolidated = googleReviews.concat(facebookReviews);

    consolidated = consolidated.filter(rev => rev.rating || rev.platform)

    let gridSize = `reviews-${consolidated.length}`

    const reviewsContainer = classnames({
        "all-reviews-container-flex": isColumn,
        "all-reviews-container-grid": !isColumn,
        [gridSize]: !isColumn,
    })

    // add AOS animation attributes if using animation
    // otherwise will make links not clickable
    let attrs = {}
    if (animation) {
        attrs["data-aos"] = animate,
        attrs["data-aos-duration"] = 1200
    }

    return (
        <section className={wrapperClasses}>
            <div 
                className="container" 
                {...attrs}
            >

            {heading && <div className="columns">
                    <div className="column is-2"></div>
                    <div className="column is-20">
                        <h2 className="has-text-centered h3-style">{heading}</h2>
                    </div>
                <div className="column is-2"></div>
            </div>
            } 
        <div className="rating-reviews home-reviews columns is-centered iconless big-rating reviews-width" style={{marginLeft: "auto", marginRight: "auto"}}>
            {/* <div className="column is-2"></div> */}
            <div className={reviewsContainer} style={{flexDirection: isColumn ? "column" : "row"}}>
            {consolidated && consolidated.map((review, i) => (
                <div key={i} className="featured-social">
                <div>
                    {!review.name && isColumn
                        ? <></>
                        :<span
                        className="reviews-city"
                        style={{
                            fontSize: "0.875rem",
                            lineHeight: "1.71",
                            color: "#6d6e6f",
                            display: "block",
                            marginBottom: review.name ? "-4px" : "20px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat"
                        }}
                        >
                            {!review.name
                                ? ""
                                : review.name && language === "es"
                                    ? "Oficina de " + review.name
                                    : review.name   + " Office"
                            }
                            {/* { review.name ? review.name + " Office" : ""} */}
                        </span>
                    }
                <a
                    className="link-wrapper"
                    href={review.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <h3>
                        {review.platform === "googleMyBusiness" ? "Google" : "Facebook"}
                    </h3>
                    <div className="total-reviews">({review.totalReviews})</div>
                </a>
                </div>
                <div>
                <a href={review.url} className="reviews-link" target="_blank">
                    <div className="review-icons">
                    <Stars rating={review.rating} />
                    </div>

                </a>
                <a href={review.url} className="reviews-link" target="_blank">
                    <span className="reviews-number">
                    {review.rating === 5 ?
                    "5.0"
                    :
                    review.rating
                    }
                    </span>
                </a>
                </div>
            </div>
            ))}
            </div>
            {/* <div className="column is-2"></div> */}
        </div>
        </div>

        { buttonHref && <div className="columns has-text-centered">
            <div className="column">
                <Button
                    contained
                    buttonText={buttonText}
                    href={buttonHref}
                />
                </div>
            </div>
        } 
      </section>
    )
}

export default AllSocialReviews
